export const LOG_IN = "LOG_IN"
export const LOG_OUT = "LOG_OUT"
export const CONFIGURATION = "CONFIGURATION"
export const FETCH_USERS = "FETCH_USERS"
export const FETCH_USER = "FETCH_USER"
export const FETCH_CARS = "FETCH_CARS"
export const FETCH_CLIENT = "FETCH_CLIENT"
export const FETCH_CLIENTS = "FETCH_CLIENTS"
export const FETCH_TESTIMONIALS = "FETCH_TESTIMONIALS"
export const FETCH_ENTITIES = "FETCH_ENTITIES"
export const FETCH_SOLICITUDES = "FETCH_SOLICITUDES"
export const FETCH_SOLICITUD = "FETCH_SOLICITUD"
export const FETCH_BANNER = "FETCH_BANNER"
export const FETCH_BLOG = "FETCH_BLOG"
export const FETCH_MENUS = "FETCH_MENUS"
export const SELECT_CAR = "SELECT_CAR"
export const REDIRECT = "REDIRECT"
export const LOADING = "LOADING"
export const ADD_TOAST = "ADD_TOAST"
export const REMOVE_TOAST = "REMOVE_TOAST"
export const RELOAD = "RELOAD"


//export const APIpath = 'http://localhost:3001'
export const APIpath= 'https://api.sv.easycarca.com/'

export * from './auth'
export * from './config'
export * from './testimonial'
export * from './banner'
export * from './blog'
export * from './entities'
export * from './client'
export * from './car'
export * from './contact'
export * from './solicitud'