import axios from 'axios'
import {
    FETCH_BANNER,
    APIpath,
    addToast,
    setRedirect
} from '../actions'

const setBanners = (banners) => ({
    type: FETCH_BANNER,
    banners
})

export const fetchActiveBanners = () => (dispatch) => {
    dispatch(setRedirect(false))
    axios.get(`${APIpath}/banner/active-banner`)
        .then(function (response) {
            dispatch(setBanners(response.data))
        })
        .catch(function (err) {
            dispatch(addToast('error', 'El proceso ha fallado'))
        })
}

export const fetchBannerBlog = () => (dispatch) => {
    dispatch(setRedirect(false))
    axios.get(`${APIpath}/banner/banner-blog`)
        .then(function (response) {
            dispatch(setBanners(response.data))
        })
        .catch(function (err) {
            dispatch(addToast('error', 'El proceso ha fallado'))
        })
}
