import axios from 'axios'
import {
    FETCH_BLOG,
    APIpath,
    addToast,
    setRedirect
} from '../actions'

const setBlogs = (blogs) => ({
    type: FETCH_BLOG,
    blogs
})

export const fetchActiveBlogs = () => (dispatch) => {
    dispatch(setRedirect(false))
    axios.get(`${APIpath}/blog/active-post`)
        .then(function (response) {
            dispatch(setBlogs(response.data))
        })
        .catch(function (err) {
            dispatch(addToast('error', 'El proceso ha fallado'))
        })
}
