import { Card, CardBody, Image, Text, Flex, Box } from '@chakra-ui/react'
import Layout from '../layout'
import agencia2 from '../multimedia/imagenes/santaana.jpg'
import agencia3 from '../multimedia/imagenes/sanmiguel.jpg'
import agencia4 from '../multimedia/imagenes/merliot.jpg'
import agencia5 from '../multimedia/imagenes/sanbenito.jpg'
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api'
import ModalApp from "../containers/Modal"

const Agencias = () => {

    return (
        <Layout>
            <LoadScript googleMapsApiKey="AIzaSyCggvraT4pGMXL0J4wLlfp_b5yoViJ95EY" >
                <Flex flexDir={{ base: 'column', lg: 'row' }} flexWrap={"wrap"} justifyContent={"space-around"} alignItems={"center"} p={"6"} mt={"10px"} gap={"10px"}>


                    <Card direction={"column"} alignItems={"center"} width={"100%"} minW={"300px"} maxW={"500px"} minHeight={"500px"} mt={"10px"}>
                        <Box maxH={"350px"} overflow={"hidden"}>
                            <Image src={agencia4} w={"100%"} objectFit={'cover'} />
                        </Box>
                        <CardBody>
                            <Text as={"b"}>Agencia Merliot</Text>
                            <Text>Blvd Merliot, Urb jardines de la hacienda, Antiguo Cuscatlán, La Libertad</Text>
                            <Text as={"b"}>Télefono:</Text>
                            <Text>+503 7507-1617</Text>
                            <ModalApp title={"Agencia Merliot"} >

                                <GoogleMap
                                    zoom={16}
                                    mapContainerStyle={{ width: '100%', height: '500px' }}
                                    center={{ lat: 13.676408970831542, lng: -89.263336317791 }}
                                >
                                    <Marker position={{ lat: 13.676408970831542, lng: -89.263336317791 }} ></Marker>
                                </GoogleMap>
                                <br />
                                <Text as={"b"}>Ubicación:</Text>
                                <Text>Blvd Merliot, Urb jardines de la hacienda, Antiguo Cuscatlán, La Libertad</Text>
                                <br />
                                <Text as={"b"}>Horario:</Text>
                                <Text>Lunes a viernes de 8:00 am a 5:00 pm</Text>
                                <Text>Sábado de 8:00 am a 12:00 md</Text>
                                <br />
                                <Text as={"b"}>Télefono:</Text>
                                <Text>+503 7507-1617</Text>
                            </ModalApp>
                        </CardBody>
                    </Card>

                    <Card direction={"column"} alignItems={"center"} width={"100%"} minW={"300px"} maxW={"500px"} minHeight={"500px"} mt={"10px"}>
                        <Box maxH={"350px"} overflow={"hidden"}>
                            <Image src={agencia5} w={"100%"} objectFit={'cover'} />
                        </Box>
                        <CardBody>
                            <Text as={"b"}>Agencia Integral San Benito</Text>
                            <Text>Ave. de La Revolución y Ave. La Capilla Colonia San Benito</Text>
                            <Text as={"b"}>Télefono:</Text>
                            <Text>+503 7567-3475</Text>
                            <ModalApp title={"Agencia Integral San Benito"} >

                                <GoogleMap
                                    zoom={16}
                                    mapContainerStyle={{ width: '100%', height: '500px' }}
                                    center={{ lat: 13.688912530103556, lng: -89.23951981957526 }}
                                >
                                    <Marker position={{ lat: 13.688912530103556, lng: -89.23951981957526 }} ></Marker>
                                </GoogleMap>
                                <br />
                                <Text as={"b"}>Ubicación:</Text>
                                <Text>Ave. de La Revolución y Ave. La Capilla Colonia San Benito</Text>
                                <br />
                                <Text as={"b"}>Horario:</Text>
                                <Text>Lunes a viernes de 8:00 am a 5:00 pm</Text>
                                <Text>Sábado de 8:00 am a 12:00 md</Text>
                                <br />
                                <Text as={"b"}>Télefono:</Text>
                                <Text>+503 7567-3475</Text>
                            </ModalApp>
                        </CardBody>
                    </Card>

                    <Card direction={"column"} alignItems={"center"} width={"100%"} minW={"300px"} maxW={"500px"} minHeight={"500px"} mt={"10px"}>
                        <Box maxH={"350px"} overflow={"hidden"}>
                            <Image src={agencia2} w={"100%"} objectFit={'cover'} />
                        </Box>
                        <CardBody>
                            <Text as={"b"}>Agencia Santa Ana</Text>
                            <Text>Centro Comercial Plaza Bolcaña, Local 6,<br />Av. Fray Felipe de Jesús Sur, Santa Ana.</Text>
                            <Text as={"b"}>Télefono:</Text>
                            <Text>+503 7583-3091</Text>
                            <ModalApp title={"Agencia Santa Ana"} >

                                <GoogleMap
                                    zoom={16}
                                    mapContainerStyle={{ width: '100%', height: '500px' }}
                                    center={{ lat: 13.978647416737255, lng: -89.56907257116468 }}
                                >
                                    <Marker position={{ lat: 13.978647416737255, lng: -89.56907257116468 }} ></Marker>
                                </GoogleMap>
                                <br />
                                <Text as={"b"}>Ubicación:</Text>
                                <Text>Centro Comercial Plaza Bolcaña, Local6,<br />Av. Fray Felipe de Jesús Sur, Santa Ana.</Text>
                                <br />
                                <Text as={"b"}>Horario:</Text>
                                <Text>Lunes a viernes de 8:00 am a 5:00 pm</Text>
                                <Text>Sábado de 8:00 am a 12:00 md</Text>
                                <br />
                                <Text as={"b"}>Télefono:</Text>
                                <Text>+503 7583-3091</Text>
                            </ModalApp>
                        </CardBody>
                    </Card>

                    <Card direction={"column"} alignItems={"center"} width={"100%"} minW={"300px"} maxW={"500px"} minHeight={"500px"} mt={"10px"}>
                        <Box maxH={"350px"} overflow={"hidden"}>
                            <Image src={agencia3} w={"100%"} objectFit={'cover'} />
                        </Box>
                        <CardBody>
                            <Text as={"b"}>Agencia San Miguel</Text>
                            <Text>Carretera Panamericana, contiguo, Avenida Central 113, San Miguel</Text>
                            <Text as={"b"}>Télefono:</Text>
                            <Text>+503 7929-9348</Text>
                            <ModalApp title={"Agencia San Miguel"} > ,

                                <GoogleMap
                                    zoom={16}
                                    mapContainerStyle={{ width: '100%', height: '500px' }}
                                    center={{ lat: 13.495409270207881, lng: -88.20050329325409 }}
                                >
                                    <Marker position={{ lat: 13.495409270207881, lng: -88.20050329325409 }} ></Marker> ,
                                </GoogleMap>
                                <br />
                                <Text as={"b"}>Ubicación:</Text>
                                <Text>Carretera Panamericana, contiguo, Avenida Central 113, San Miguel</Text>
                                <br />
                                <Text as={"b"}>Horario:</Text>
                                <Text>Lunes a viernes de 8:00 am a 5:00 pm</Text>
                                <Text>Sábado de 8:00 am a 12:00 md</Text>
                                <br />
                                <Text as={"b"}>Télefono:</Text>
                                <Text>+503 7929-9348</Text>
                            </ModalApp>
                        </CardBody>
                    </Card>



                </Flex>
            </LoadScript>
        </Layout>
    )

}

export default Agencias