
import { Flex, Box, Text, Button, Image } from '@chakra-ui/react'
import { APIpath } from '../actions'
import { Link } from 'react-router-dom'
import CarouselMulti from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'

const bannerStyle = (banner) => {

    //diseno
    let textSide = ""
    let imageSide = ""
    let textSize = { base: "full", lg: "40%" }
    let imageSize = { base: "full", lg: "60%" }
    let textOrder = { base: 2, lg: 1 }
    let imageOrder = { base: 1, lg: 2 }

    //Color diseno
    let bannerColor = "easycar1.500"
    let textColor = ""
    let buttonColor = "easycar1"

    //Color
    switch (banner.colorDesign) {
        //Gris
        case 2:
            bannerColor = "easycar2.500"
            textColor = "colorWhite"
            buttonColor = "easycar1"
            break
        //Blanco
        case 3:
            bannerColor = "white.500"
            textColor = ""
            buttonColor = "easycar1"
            break
        default:
            bannerColor = "easycar1.500"
            textColor = "colorWhite"
            buttonColor = "easycar2"
            break
    }

    //Diseno
    switch (banner.design) {
        case 2:
            textSide = "sideRight3"
            textOrder = { base: 2, lg: 2 }
            imageSide = "sideLeft3"
            imageOrder = { base: 1, lg: 1 }
            break
        case 3:
            textSide = "sideLeft"
            textOrder = { base: 2, lg: 1 }
            imageSide = "sideRight"
            imageOrder = { base: 1, lg: 2 }
            break
        case 4:
            textSide = "sideLeft4"
            imageSide = "sideRight4"
            textOrder = { base: 2, lg: 1 }
            imageOrder = { base: 1, lg: 2 }
            break
        case 5:
            textSide = "sideRight2"
            imageSide = "sideLeft2"
            textOrder = { base: 2, lg: 2 }
            imageOrder = { base: 1, lg: 1 }
            break
        default:
            textSize = { base: "0%", lg: "full" }
            imageSize = { base: "full", lg: "full" }
            textOrder = { base: 0, lg: 0 }
            imageOrder = { base: 1, lg: 2 }
            break
    }


    return {
        textSide,
        imageSide,
        bannerColor,
        textColor,
        textOrder,
        imageOrder,
        buttonColor,
        textSize,
        imageSize
    }
}


const HeroCarousel = ({ banners }) => {

    const responsive = {
        largeDesktop: {
            breakpoint: { max: 3000, min: 1400 },
            items: 1,
            partialVisibilityGutter: 0
        },
        desktop: {
            breakpoint: { max: 1400, min: 1024 },
            items: 1,
            partialVisibilityGutter: 0
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 1,
            partialVisibilityGutter: 0
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1,
            partialVisibilityGutter: 0
        }
    };

    return (

        <CarouselMulti
            showDots={false}
            rewind={true}
            rewindWithAnimation={true}
            responsive={responsive}
            autoPlay={true}
            autoPlaySpeed={5000}

        >

            {banners.map(banner => {

                let style = bannerStyle(banner)

                return (
                    <Flex flexDir={{ base: 'column', lg: 'row' }} key={banner.id} bg={style.bannerColor}>
                        {banner.design !== 1 ?
                            <Box order={style.textOrder} className={style.textSide} w={"full"} maxW={style.textSize} p={6} pl={"5%"} m={"auto"} minH={{ base: '200px', lg: "auto" }} >
                                <Text fontSize={{ base: "initial", lg: "xl" }} className={style.textColor}>
                                    <div dangerouslySetInnerHTML={{ __html: banner.text }} />
                                </Text>

                                {banner.buttonUrl === null ?
                                    <Link to={"/cars"}>
                                        <Button colorScheme={style.buttonColor} > {banner.buttonText}</Button>
                                    </Link>
                                    :
                                    <a href={banner.buttonUrl} >
                                        <Button colorScheme={style.buttonColor} > {banner.buttonText}</Button>
                                    </a>
                                }
                            </Box>
                            :
                            undefined
                        }
                        <Box order={style.imageOrder} className={style.imageSide} w={"full"} maxW={style.imageSize} bg={"white"}>
                            {banner.buttonUrl === null ?
                                <Link to={"/cars"}>
                                    <Image src={`${APIpath}/banner/image/${banner.id}`} alt={'Carousel Image'} objectFit={'cover'} h={{ base: '400px', lg: "700px" }} w={"full"} />
                                </Link>
                                :
                                <a href={banner.buttonUrl} >
                                    <Image src={`${APIpath}/banner/image/${banner.id}`} alt={'Carousel Image'} objectFit={'cover'} h={{ base: '400px', lg: "700px" }} w={"full"} />
                                </a>
                            }
                        </Box>
                    </Flex>
                )
            })}

        </CarouselMulti>
    )
}

export default HeroCarousel