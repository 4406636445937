import { combineReducers } from 'redux'
import users, * as selectorsFromUser from './users'
import clients, * as selectorsFromClient from './clients'
import auth, * as selectorsFromAuth from './auth'
import testimonials, * as selectorsFromTestimonial from './testimonials'
import banner, * as selectorsFromBanner  from './banner'
import blog, * as selectorsFromBlog  from './blog'
import config, * as selectorsFromConfig from './config'
import entities, * as selectorsFromEntity from './entities'
import cars, * as selectorsFromCar from './cars'
import solicitudes, * as selectorsFromSolicitudes  from './solicitudes'
import ui, * as selectorsFromUi from './ui'

const rootReducer = combineReducers({
    auth,
    users,
    clients,
    cars,
    solicitudes,
    testimonials,
    banner,
    blog,
    entities,
    config,
    ui
})

export const getUsers = (state) => selectorsFromUser.getUsers(state.users)
export const getUser = (state, id) => selectorsFromUser.getUser(state.users, id)

export const getClients = (state) => selectorsFromClient.getClients(state.clients)
export const getClient = (state, id) => selectorsFromClient.getClient(state.clients, id)

export const getCars = (state) => selectorsFromCar.getCars(state.cars)
export const getCar = (state, id) => selectorsFromCar.getCar(state.cars, id)

export const getAuth = (state) => selectorsFromAuth.getAuth(state.auth)
export const getRole = (state) => selectorsFromAuth.getRole(state.auth)

export const getTestimonials = (state) => selectorsFromTestimonial.getTestimonials(state.testimonials)
export const getTestimonial = (state, id) => selectorsFromTestimonial.getTestimonial(state.testimonials, id)

export const getBanners = (state) => selectorsFromBanner.getBanners(state.banner)
export const getBanner = (state, id) => selectorsFromBanner.getBanner(state.banner, id)

export const getPosts = (state) => selectorsFromBlog.getPosts(state.blog)
export const getPost = (state, id) => selectorsFromBlog.getPost(state.blog, id)

export const getConfig = (state) => selectorsFromConfig.getConfig(state.config)
export const getEntity = (state, name) => selectorsFromEntity.getEntity(state.entities, name)

export const getLoader = (state) => selectorsFromUi.getLoader(state.ui)
export const getRedirect = (state) => selectorsFromUi.getRedirect(state.ui)
export const getMenu = (state) => selectorsFromUi.getMenu(state.ui)
export const getCarSelected = (state) => selectorsFromUi.getCarSelected(state.ui)

export const getSolicitudes = (state) => selectorsFromSolicitudes.getSolicitudes(state.solicitudes)
export const getSolicitud = (state, id) => selectorsFromSolicitudes.getSolicitud(state.solicitudes, id)

export const getActiveToast = (state) => selectorsFromUi.getActiveToast(state.ui)

export default rootReducer