import React from 'react'
import { BrowserRouter as Router, Route, Switch } from "react-router-dom"
import { connect } from 'react-redux'
import { fetchConfiguration, fetchEntity } from './actions'
import PrivateRoute from './containers/PrivateRoute'
import Error404 from './components/404'
import ErrorBoundary from './components/ErrorBoundary'
import Toast from './containers/Toast'

import Landing from './pages/Landing'
import Agencias from './pages/Agencias'
import PuntoPago from './pages/PuntoPago'
import Requisitos from './pages/Requisitos'
import Faq from './pages/faq'
import Vende from './pages/Vende'
import Experiencia from './pages/Experiencia'
import Cars from './pages/Cars'
import CarDetail from './pages/CarDetail'
import Login from './pages/Login'
import RegisterActivate from './pages/RegisterActivate'
import Contact from './pages/Contact'
import Profile from './pages/Profile'
import Documents from './pages/Documents'
import Leasing from './pages/Leasing'
import FinanciamientoStatus from './pages/FinanciamientoStatus'
import FinanciamientoDetailStatus from './pages/FinanciamientoDetailStatus'
import Aviso_privacidad from './pages/Aviso_privacidad'
import Terminos_condiciones from './pages/Terminos_condiciones'
import BlogLanding from './pages/BlogLanding'
import BlogPost from './pages/BlogPost'

class App extends React.Component {

  componentDidMount() {
    this.props.fetchConfiguration()
    this.props.fetchEntity('departamento')
  }

  render() {
    return (
      <Router>
        <ErrorBoundary>
          <Switch>
            <Route path={"/"} exact component={Landing} />
            <Route path={"/login"} exact component={Login} />
            <Route path={"/agencias"} exact component={Agencias} />
            <Route path={"/puntos-pago"} exact component={PuntoPago} />
            <Route path={"/experiencia-easycar"} exact component={Experiencia} />
            <Route path={"/requisitos"} exact component={Requisitos} />
            <Route path={"/vende"} exact component={Vende} />
            <Route path={"/faq"} exact component={Faq} />
            <Route path={"/terminos-condiciones"} exact component={Terminos_condiciones} />
            <Route path={"/aviso-privacidad"} exact component={Aviso_privacidad} />
            <Route path={"/contacto"} exact component={Contact} />
            <Route path={"/cars"} exact component={Cars} />
            <Route path={"/car/:id"} component={CarDetail} />
            <Route path={"/cotizar"} exact component={Leasing} />
            <Route path={"/register/activate/:id/:code"} component={RegisterActivate} />
            <Route path={"/blog"} exact component={BlogLanding} />
            <Route path={"/blog/:id"} component={BlogPost} />
            <PrivateRoute path={"/status"} exact component={FinanciamientoStatus} />
            <PrivateRoute path={"/status/:id"} component={FinanciamientoDetailStatus} />
            <PrivateRoute path={"/profile"} exact component={Profile} />
            <PrivateRoute path={"/profile/documents"} exact component={Documents} />
            <Route path={"*"} component={Error404} />
          </Switch>
          <Toast />
        </ErrorBoundary>
      </Router>
    )
  }

}

const mapStateToProps = (state) => ({
  loader: false
})

const mapDispatchToProps = { fetchConfiguration, fetchEntity }

export default connect(mapStateToProps, mapDispatchToProps)(App)